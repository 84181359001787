define("discourse/plugins/pg-archived-comments/discourse/components/pg-comments-list", ["exports", "discourse/lib/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["pg-topic-tip"],
    didInsertElement() {
      this._super(...arguments);
      $(document).on("click", Ember.run.bind(this, this.documentClick));
      const rawDetails = I18n.t(this.details, this.detailsOpts);
      if (rawDetails) {
        (0, _text.cookAsync)(rawDetails).then(cooked => {
          this.set("cookedDetails", cooked);
        });
      }
    },
    willDestroyElement() {
      $(document).off("click", Ember.run.bind(this, this.documentClick));
    },
    documentClick(e) {
      const $element = $(this.element);
      const $target = $(e.target);
      if ($target.closest($element).length < 1 && this._state !== "destroying") {
        this.set("showDetails", false);
      }
    },
    actions: {
      toggleDetails() {
        this.toggleProperty("showDetails");
      }
    }
  });
});