define("discourse/plugins/pg-archived-comments/discourse/initializers/comment_button", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "I18n", "discourse/lib/ajax-error", "discourse/lib/ajax", "virtual-dom", "discourse/lib/posts-with-placeholders"], function (_exports, _pluginApi, _decorators, _I18n, _ajaxError, _ajax, _virtualDom, _postsWithPlaceholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import postStream from "../../../../../app/assets/javascripts/discourse/app/models/post-stream";
  var _default = _exports.default = {
    name: "raw-post-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        const currentUser = api.getCurrentUser();
        if (!currentUser) return;

        /* Render PG Comments */
        const renderPgComments = function renderPgComments(data, model, _page) {
          var _pg_comment_html = "";
          var _pg_comment_pagination_section = '<div class="pg-comment-more-section" style="padding: 10px; text-align: center;"></div>';
          var _pg_comment_pagination = "";
          jQuery(".pgy-comments-" + model.id).removeClass("hidden");
          // jQuery(".pgy-comments-"+model.id).html("Loading...");
          jQuery(".pgy-comments-" + model.id).css({
            "border-left": "5px solid #8790ff",
            "padding-left": "0px",
            "border-bottom": "none",
            "margin-bottom": "20px",
            "background": "#FFF",
            "background": "linear-gradient( 90deg, rgb(245 245 255) 0%, rgb(255 255 255) 35%, rgb(255 255 255) 100%)",
            "position": "relative"
          });
          var _pg_comment_close_btn = '<span class="pg-comment-close-btn" id="pg_comment_close_btn_' + model.id + '" style="position: absolute;right: -8px;padding: 2px 8px;display: block;z-index: 2;background: #e9e9e9;font-size: 12px;top: -10px;cursor:pointer;border: 1px solid #cecece;">x</span>';

          // remove the old close btn
          if (jQuery(".pgy-comments-" + model.id + " .pg-comment-close-btn").length > 0) {
            jQuery(".pgy-comments-" + model.id + " .pg-comment-close-btn")[0].remove();
          }
          // add the close btn
          _pg_comment_html += _pg_comment_close_btn;

          // const downloadLink = document.createElement("a");
          let _comments = data.comments.length > 0 ? data.comments : "";
          let _show_pagination = data.comments.length > 3 ? true : false;
          if (_comments) {
            _comments.forEach(pg_comment => {
              _pg_comment_pagination = _show_pagination ? '<div style="text-align: center;"><button class="btn btn-default pgy-comments-load-more" id="' + pg_comment.id + '" data-pagenumber="' + _page + '">Load More</button></div>' : '';
              _pg_comment_html += '<article style="width: 100%; display: inline-block; border-bottom: 1px solid #DDD;" class="boxed onscreen-post">\
                    <div class="row">\
                        <div class="topic-body clearfix" style="border: none; background: none; width: 100%; float: none;">\
                            <div class="topic-meta-data">\
                                <div class="names trigger-user-card">\
                                    <span class="first username staff admin">\
                                        <a href="/u/' + pg_comment.username + '" data-user-card="' + pg_comment.username + '">@' + pg_comment.username + '</a>\
                                    </span>\
                                </div>\
                                <div class="post-infos">\
                                    <div class="post-info post-date">\
                                        <a class="post-date" href="' + model.shareUrl + '" data-share-url="' + model.shareUrl + '" data-post-number="2">\
                                            <span title="' + pg_comment.created_at + '" data-time="' + pg_comment.created_at + '" data-format="tiny" class="relative-date">' + moment(pg_comment.created_at).format("MMM 'YY") + '</span>\
                                        </a>\
                                    </div>\
                                </div>\
                            </div>\
                            <div class="regular contents">\
                                <div class="cooked">' + pg_comment.content + '</div>\
                            </div>\
                        </div>\
                    </div>\
                </article>';
            });
            jQuery(".pgy-comments-" + model.id).append(_pg_comment_html);
          } else {
            jQuery(".pgy-comments-" + model.id).html("<div style='padding: 20px;'>No data found.</div>");
            jQuery(".pgy-comments-" + model.id).append(_pg_comment_close_btn);
          }

          //inject pagination section
          if (jQuery(".pgy-comments-" + model.id + " .pg-comment-more-section").length > 0) {
            jQuery(".pgy-comments-" + model.id + " .pg-comment-more-section")[0].remove(); // remove the old btn
          }

          jQuery(".pgy-comments-" + model.id).append(_pg_comment_pagination_section);
          jQuery(".pgy-comments-" + model.id + " .pg-comment-more-section").html(_pg_comment_pagination);
          jQuery('#pg_comment_close_btn_' + model.id).on('click', function () {
            jQuery(".pgy-comments-" + model.id).html("");
            if (jQuery(".pgy-comments-" + model.id + " .pg-comment-more-section").length > 0) {
              jQuery(".pgy-comments-" + model.id + " .pg-comment-more-section").remove();
            }
          });
          jQuery('.pgy-comments-load-more').on('click', function () {
            var _pagenumebr = jQuery(this).data('pagenumber');
            getPgComments(model, _pagenumebr);
          });
        };

        /* Get PG Comments */
        const getPgComments = function getPgComments(model, page) {
          let _next_page = page + 5;
          (0, _ajax.ajax)("/pg-comments", {
            type: "GET",
            data: {
              pgy_post_id: model.pgy_post_id,
              pgy_limit: 5,
              pgy_offset: page
            }
          }).then(data => {
            if (data.success === true) {
              renderPgComments(data, model, _next_page);
              // console.log("success...", data.success);
              // console.log("comments...", data.comments);
            }
          }).catch(error => {
            if (error) {
              (0, _ajaxError.popupAjaxError)(error);
            } else {
              console.log("Something went wrong.");
            }
          });
        };

        /* Pgy Number format */
        const pgyNumberFormat = function pgyNumberFormat(num) {
          if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
          } else if (num > 1000000) {
            return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
          } else if (num < 900) {
            return num; // if value < 1000, nothing to do
          }
        };

        api.attachWidgetAction("post-menu", "showPgComments", function () {
          const model = this.attrs;
          console.log("model", model.pgy_post_id);
          if (model.id) {
            //do the ajax call for fetch and populate the PG comments.
            getPgComments(model, 0);
          }
        });
        api.decorateWidget("post:after", helper => {
          const model = helper.getModel();
          if (model.firstPost) {
            return;
          }
          // console.log("helper..", helper);
          return helper.h("div.hidden.pg-comments.pg-comments-list.pgy-comments-" + model.id, "");
        });
        api.addPostMenuButton("pgcomments", attrs => {
          //check if it's first post and pgy_post_id is present
          // console.log("attrs.pgy_post_id....", attrs);
          const pgy_c_count = attrs.pgy_comments_count;

          //post_type
          if (!attrs.firstPost && pgy_c_count > 0 && attrs.pgy_post_id) {
            return {
              action: "showPgComments",
              icon: "file-alt",
              className: "pg-comments",
              title: "pgcomments.solution",
              contents: (0, _virtualDom.h)("span.pgy-c-count", pgyNumberFormat(pgy_c_count).toString()),
              label: "pgcomments.archived_btn"
              // position: "second-last-hidden"
            };
          }
        });

        api.createWidget("category-icon", {
          tagName: "div.category-icon-widget",
          html(attrs) {
            return (0, _virtualDom.h)("span.category-icon", {
              style: "background: red;"
            }, "");
          }
        });
        api.includePostAttributes("pgy_post_id", "pgy_comments_count", "pgy_discourse_topic_archetype");
      });
    }
  };
});